<template>
  <a-spin :spinning="spinning">
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <OrderSearch :showOrderStatus="'to_delivery_order'" @getSearchData="getSearchData" ref="OrderSearch"></OrderSearch>
      <div>
        <a-button v-if="isDelivery && selectedRows.length > 0" type="primary" @click="toHandler('generate')">生成发货单</a-button>
<!--        <a-button v-if="isVoid && selectedRows.length == 1 && selectedRows[0].orderStatus == 5" type="primary" @click="toHandler('void')">关闭订单</a-button>-->
<!--        <a-button v-if="isCancel && selectedRows.length == 1 && selectedRows[0].shipCount <= 0 && selectedRows[0].orderStatus != 5" type="primary" @click="toHandler('cancel')">-->
<!--          取消订单</a-button-->
<!--        >-->
        <!-- <a-button v-if="isCheck" type="primary" @click="toHandler('check')">查看</a-button> -->
        <downLoad
          method="get"
          api="/api/order/order/orderInfo/exportSingleOrderInfo"
          :params="{id:selectedRows[0].id}"
          name="订单明细.xlsx"
          v-if="selectedRows.length == 1"
        >导出发货单</downLoad
        >
        <!--        isExportSingleShipOrderInfo &&-->
        <!-- <downLoad
          method="get"
          api="/api/order/order/orderInfo/excelExportOrderInfo"
          :params="searchData"
          @downLoadDone="downLoadDone"
          @startDownLoad="startDownLoad"
          name="菱感销售单(待发货).xls"
          v-if="isExport"
          >批量导出订单</downLoad
        > -->
      </div>
      <!-- 数据表格 -->
      <!--      <toOrderList-->
      <!--        :url="'/api/order/order/orderInfo/consolidatedOrder'"-->
      <!--        :columnsData="columns"-->
      <!--        :status="[4,5]"-->
      <!--        :text="'to_delivery_order'"-->
      <!--        @orderData="getSelectedRows($event)"-->
      <!--        @changeTable="getchangeTable"-->
      <!--      ></toOrderList>-->

      <!-- 数据表格 -->
      <toOrderList
        :url="'/api/order/order/orderInfo/list'"
        :columnsData="columns"
        :status="[4,5]"
        :isToDelivery="true"
        @orderData="getSelectedRows($event)"
        @changeTable="getchangeTable"
      ></toOrderList>
      <!-- 编辑表单 -->
      <OrderInfo-edit-modal ref="OrderInfoEditModal" ></OrderInfo-edit-modal>
      <EditAddressModal ref="EditAddressModal" ></EditAddressModal>
      <InvoiceEditModal ref="InvoiceEditModal"></InvoiceEditModal>
      <OrderInfoCheckModal ref="OrderInfoCheckModal"></OrderInfoCheckModal>
      <GenerateInvoice ref="GenerateInvoice" @getConfirmDiscount="getConfirmDiscount"></GenerateInvoice>
      <a-modal
        title="操作"
        width="30%"
        :visible="visible"
        :confirm-loading="confirmLoading"
        :footer="null"
        @cancel="visible = false"
      >
        <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
          <div v-if="handle == 'audit'">
            <a-form-model-item label="是否通过">
              <a-radio-group v-model="status" @change="onStatusChange">
                <a-radio :value="4"> 通过 </a-radio>
                <a-radio :value="3"> 拒绝 </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="原因" v-if="statusRadio == 3">
              <a-textarea v-model="rejectReason"></a-textarea>
            </a-form-model-item>
          </div>
          <div v-if="handle == 'cancel' || handle == 'void'">
            <a-form-model-item label="理由">
              <a-textarea v-model="rejectReason"></a-textarea>
            </a-form-model-item>
          </div>
        </a-form-model>
        <div class="footer-bts">
          <a-button type="default" @click="visible = false">关闭</a-button>
          <a-button v-if="handle == 'void'" type="primary" @click="onModifyOrderStatus('void')">关闭订单</a-button>
          <a-button v-if="handle == 'cancel'" type="primary" @click="onModifyOrderStatus('cancel')">取消订单</a-button>
          <a-button v-if="handle == 'audit'" type="danger" @click="onModifyOrderStatus('refuse')">拒绝</a-button>
          <a-button v-if="handle == 'audit'" type="primary" @click="onModifyOrderStatus('audit')">通过</a-button>
        </div>
      </a-modal>
    </div>
  </a-card>
  </a-spin>
</template>

<script>
import OrderInfoEditModal from '@/views/order_info/components/OrderInfoEditModal.vue'
import { delOrderInfo, listOrderInfo, selectByIdOrderInfo } from './api/OrderInfoApi'
import EditAddressModal from './components/EditAddressModal.vue'
import InvoiceEditModal from './components/InvoiceEditModal.vue'
import OrderInfoCheckModal from '@/views/order_info/components/OrderInfoCheckModal.vue'
import GenerateInvoice from './components/GenerateInvoice.vue'
import { checkPermission } from '@/utils/permissions'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'orderInfo',
  components: {
    OrderInfoEditModal,
    EditAddressModal,
    InvoiceEditModal,
    OrderInfoCheckModal,
    GenerateInvoice,
  },
  data() {
    return {
      columns: [
        'orderCode',
        'bigAreaName',
        'dealerName',
        'belongDealerName',
        'flagFreeShipping',
        'deliveryProperties',
        'purchaseAndSalesContractType',
        'contractType',
        'orderType',
        'sourceType',
        'payAmount',
        'orderStatus',
        'expectTime',
        'createTime',
        'orderDate',
        'address',
        'consigneeType',
        'supplierName',
        'policyTotalAmount',
        'rebateTotalAmount',
        'linkGapContractNum',
        'stContractNum',
        'shipOrderType',
        'bucklePoint',
        'alreadySendGoodsCount',
        'goodsCount',
      ],
      tableData: [],
      searchData: { statusList: [4, 5] ,isToDelivery: true}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      isVoid: checkPermission('order:info:void_to_delivery'),
      isDelivery: checkPermission('order:info:delivery_to_delivery'),
      isExport: checkPermission('order:info:export_to_delivery'),
      isCancel: checkPermission('order:info:cancel_to_delivery'),
      visible: false,
      confirmLoading: false,
      rejectReason: '',
      handle: '',
      contractTypeList: [],
      orderTypeList: [],
      status: 4,
      statusRadio: 4,
      spinning:false
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        // fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    startDownLoad(){
      this.spinning = true
    },
    downLoadDone (){
      this.spinning = false
    },
    // 导出条件
    getSearchData(row){
     this.searchData = row
      this.searchData.statusList = [4,5]
    },
    getchangeTable(){
      this.$refs.OrderSearch.getDataFilter(1)
    },
    getConfirmDiscount(){
      this.$refs.OrderSearch.getDataFilter()
    },
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },

    getSelectedRows(rows) {
      this.selectedRows = rows
    },

    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.OrderInfoEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.OrderInfoEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delOrderInfo(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.$refs.OrderSearch.getDataFilter()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'edit_address':
          _this.$refs.EditAddressModal.isShow(_this.selectedRows[0])
          break
        case 'edit_invoice':
          _this.$refs.InvoiceEditModal.isShow(_this.selectedRows[0])
          break
        case 'audit':
          _this.$refs.OrderInfoCheckModal.isShow(_this.selectedRows[0], 'audit')
          break
        case 'void':
          _this.visible = true
          _this.handle = 'void'
          break
        case 'cancel':
          _this.visible = true
          _this.handle = 'cancel'
          break
        case 'check':
          _this.$refs.OrderInfoCheckModal.isShow(_this.selectedRows[0],'','to_delivery')
          break
        case 'generate':

          let arr = []
          let arrD = []
          for (var i = 0; i < this.selectedRows.length; i++) {
            if (arr.indexOf(this.selectedRows[i].supplierCode) === -1) {
              arr.push(this.selectedRows[i].supplierCode)
            }
          }
          for (var i = 0; i < this.selectedRows.length; i++) {
            if (arrD.indexOf(this.selectedRows[i].dealerId) === -1) {
              arrD.push(this.selectedRows[i].dealerId)
            }
          }
          if (arr.length > 1) {
            return this.$message.warning('请选择同一供应商的订单')
          }

          if (arrD.length > 1) {
            return this.$message.warning('请选择同一经销商的订单')
          }


          _this.$refs.GenerateInvoice.setRowData(_this.selectedRows, 'generate')
          break
      }
    },

    // 修改订单状态
    onModifyOrderStatus(name) {
      let status = 0
      if (name == 'audit') {
        status = 4
      } else if (name == 'refuse') {
        status = 3
      } else if (name == 'void') {
        status = 1
      } else if (name == 'cancel') {
        status = 2
      }
      let obj = {
        orderId: this.selectedRows[0].id,
        orderStatus: status,
        verifyRemark: this.rejectReason,
      }
      this.axios.post(`/api/order/order/orderInfo/verifyOrderInfo`, obj).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.visible = false
          this.$refs.OrderSearch.getDataFilter()
          this.rejectReason = ''
          this.selectedRowKeys = []
        }
      })
    },

    onStatusChange(val) {
      this.statusRadio = val.target.value
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
.footer-bts {
  text-align: right;
}
</style>
